import React from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  mainAboutTitle, mainAboutText,
  mainServicesTitle, services,
  mainPartnersTitle, partners,
  mainBlogTitle, blogsMain
} from '../content';
import { CardBlog, CardServiceDark } from '../component';

const Main = () => {

  const navigate = useNavigate()

  const onClickContact = () => {
    navigate('/contact');
    window.scrollTo(0, 0);
  }

  const onClickAbout = () => {
    navigate('/about');
    window.scrollTo(0, 0);
  }

  const onClickServices = () => {
    navigate('/services')
    window.scrollTo(0, 0);
  }

  const onClickPartners = () => {
    navigate('/partners')
    window.scrollTo(0, 0);
  }

  const onClickBlogs = () => {
    navigate('/blogs')
    window.scrollTo(0, 0);
  }

  return (
    <div>

      <section className="hero-section">
        <h1 className="hero-title"><span className="hero-highlight">Empowering</span> Success with <br/>IT & Training <span className="hero-highlight">Excellence.</span></h1>
        <button onClick={onClickContact} className="btn-fill hero-button">CONTACT US</button>
      </section>

      <section className="about-section">
        <h2 className="common-h2 about-title">{mainAboutTitle}</h2>
        <p className="common-p about-text">{mainAboutText}</p>
        <button onClick={onClickAbout} className="btn-fill about-button">KNOW MORE ABOUT US!</button>
      </section>

      <section className="services-section">
        <h2 className="common-h2 services-title">{mainServicesTitle}</h2>
        <div className="services-grid">
          {services.slice(0,4).map((service, i) => (
            <CardServiceDark service={service} key={i} />
          ))}
        </div>
        <button onClick={onClickServices} className="btn-fill service-button">VIEW SERVICES</button>
      </section>

      <section className="partners-section">
        <h2 className="common-h2 partners-title">{mainPartnersTitle}</h2>
        <div className="partners-flex">
          {partners.slice(0,4).map((partner, i) => (
            <img key={i} className="partner-mainpage-image" src={partner.image} alt="partner name" />
          ))}
        </div>
        <button onClick={onClickPartners} className="btn-fill partners-button">VIEW PARTNERS</button>
        </section>

      <section className="blogs-section">
        <h2 className="common-h2 blogs-title">{mainBlogTitle}</h2>
        <div className="blogs-grid">
          {blogsMain.slice(0,4).map((blog, i) => (
            <CardBlog key={i} blog={blog} />
          ))}
        </div>
        <button onClick={onClickBlogs} className="btn-fill blogs-button">VIEW BLOGS</button>
      </section>

    </div>
  );
};

export default Main;
