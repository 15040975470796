import React from 'react'

const CardPartners = ({partner}) => {
    return (
        <div className="partner-card" onClick={() => window.open(partner.linkedIn, "_blank")}>
            <img className="partner-image" src={partner.image} alt={partner.altText} />
            <div className="partner-overlay"></div>
        </div>
    )
}

export default CardPartners
