import React from 'react'
import { useNavigate } from 'react-router-dom';

const CtaFooter = () => {
  const navigate = useNavigate()

  const onClickAboutUs = () => {
    navigate('/about');
    window.scrollTo(0, 0);
  }

  return (
    <div className='cta-footer'>
        <h4 className='cta-footer__title'>
            Want to know more <span className='cta-footer__highlight'>about us</span>?
        </h4>
        <h4 className='cta-footer__title'>
            Click here to uncover our story and get in touch—we’d love to <span className='cta-footer__highlight'>connect!</span>
        </h4>
        <button onClick={onClickAboutUs} className='cta-footer__button'>KNOW MORE ABOUT US!</button>
    </div>
  )
}

export default CtaFooter
