import React from 'react'
import iconMission from '../images/aboutus/iconMission.png'
import iconVision from '../images/aboutus/iconVision.png'

const CardMissionVision = () => {
  return (
    <div className="mv-section">
        <div className="mv-section__up">
            <img className="mv-icon" src={iconMission} alt="Mission Icon" />
            <div className="mv-content">
                <h4>Our Mission</h4>
                <p>To be the leading provider of innovative staffing solutions that empower organizations to achieve their fullest potential, while transforming careers and fostering long-lasting partnerships.</p>
            </div>
        </div>
        <div className="mv-section__down">
            <div className="mv-content">
                <h4>Our Vision</h4>
                <p>Our vision is to be the trusted technology partner for our clients, delivering innovative solutions and leadership. We are committed to giving back to our communities, supporting initiatives that enhance lives and foster growth.</p>
            </div>
            <img className="mv-icon" src={iconVision} alt="Vision Icon" />
        </div>
    </div>
  )
}

export default CardMissionVision
