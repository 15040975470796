import { useEffect, useState } from "react"
import { useParams, useNavigate } from 'react-router-dom';
import { serviceSlug } from '../content'
import { CtaFooter, SectionHero } from '../component'
import Markdown from "markdown-to-jsx"

const Post = () => {
    const { slug } = useParams();
    const [postContent, setPostContent] = useState("");
    const [postMeta, setPostMeta] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        import(`../content/service/${slug}.md`)
        .then(res => {
            fetch(res.default)
            .then(res => res.text())
            .then(res => setPostContent(res))
            .catch(err => console.log(err))
        })
        
        if (serviceSlug[slug]) {
            setPostMeta(serviceSlug[slug])
        }
    }, []);

    return (
        <div id="markdown-wrapper" className="common-wrapper">

            <SectionHero  text="SERVICES" />

            <div className="markdown-container">
                {postMeta && <img className="markdown-image" src={postMeta.image} alt="logo main" />}
                <div className="markdown-content">
                    <Markdown>
                        {postContent}
                    </Markdown>
                </div>
            </div>
            
            <div className="markdown-btn-container">
                <button onClick={() => {
                    navigate('/services')
                    window.scrollTo(0, 0);
                }} className="markdown-btn">GO BACK TO SERVICES</button>
            </div>

            <div className="common-container">
                <CtaFooter />
            </div>

        </div>
    )
}

export default Post