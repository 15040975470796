import React from 'react'
import { CtaFooter, CtaHeader, CardService, SectionHero } from '../component'
import { services } from '../content'

const Services = () => {
  return (
    <div className='common-wrapper'>

      <SectionHero  text="SERVICES" />

      <div className='common-container'>

        <CtaHeader 
            heading='Our services' 
            text='Discover our range of expert solutions tailored to meet your unique needs!' 
        />

        <div className='services-grid-wrapper'>
          <div className='services-grid'>
            {services.map((service, i) => (
              <CardService key={i} service={service} />
            ))}
          </div>
        </div>

        <CtaFooter />
      </div>
    </div>
  )
}

export default Services
