import iconEmail from '../images/contactus/iconEmail.png';
import iconLinkedIn from '../images/contactus/iconLinkedIn.png';
import iconLocation from '../images/contactus/iconLocation.png';
import iconPhone from '../images/contactus/iconPhone.png';

import partnerImageRandstad from '../images/ourpartners/rect_1randstad.jpg';
import partnerImageTek from '../images/ourpartners/rect_2tek.jpg';
import partnerImageAccenture from '../images/ourpartners/rect_3accenture.jpg';
import partnerImageJade from '../images/ourpartners/rect_4jade.jpg';

import imgBlogOne from '../images/blogs/blog1.jpg';
import imgBlogTwo from '../images/blogs/blog2.jpg';
import imgBlogThree from '../images/blogs/blog3.jpg';
import imgBlogFour from '../images/blogs/blog4.jpg';

import imgOurServiceOne from '../images/ourservices/service1.jpg';
import imgOurServiceTwo from '../images/ourservices/service2.jpg';
import imgOurServiceThree from '../images/ourservices/service3.jpg';

import iconValueOne from '../images/aboutus/iconValue1.png';
import iconValueTwo from '../images/aboutus/iconValue2.png';
import iconValueThree from '../images/aboutus/iconValue3.png';
import iconValueFour from '../images/aboutus/iconValue4.png';
import iconValueFive from '../images/aboutus/iconValue5.png';
import iconValueSix from '../images/aboutus/iconValue6.png';
import imgLeadOne from '../images/aboutus/lead1.jpg';
import imgLeadDummy from '../images/aboutus/leaddummy.png';
import imgCertificateOne from '../images/aboutus/cartificate1.png';
import imgCertificateTwo from '../images/aboutus/certificate2.png';

// MAIN PAGE

export const mainAboutTitle = "Who Are We";
export const mainAboutText = "Welcome to ITB! We specialize in cutting-edge IT solutions and top-notch training to drive your business forward. Explore our services and discover how we can help you achieve your goals with confidence. Let's succeed together!";

export const mainServicesTitle = "Services";
export const mainPartnersTitle = "Our Partners";
export const mainBlogTitle = "Blogs";

// NAVBAR

export const navbarMenuItems = [
    { text: "Home", link: "/" },
    { text: "Services", link: "/services" },
    { text: "Blogs", link: "/blogs" },
    { text: "Our Partners", link: "/partners" },
    { text: "Career", link: "/career" },
    { text: "About Us", link: "/about" },
];

// CONTACT US PAGE

export const contactDetails = {
    headLinkedIn: '',
    headLinkedInUrl: '',
    headNumber: '+1 469 820 8276',
    headEmail: 'contact@itdatabuild.com',
    headAddress: '3700 River Walk Dr., Flower Mound, TX - 75028',
    indiaNumber: '+91 7838 732728',
    indiaEmail: 'contact_india@itdatabuild.com',
    indiaAddress: 'Noida, India',
    iconEmail,
    iconLinkedIn,
    iconLocation,
    iconPhone
}

// PARTNERS

export const partners = [
    {image: partnerImageRandstad, linkedIn: 'https://www.randstadusa.com/?utm_term=randstad&utm_campaign=Brand+-+Core&utm_source=adwords&utm_medium=ppc&hsa_acc=6158588478&hsa_cam=11770720256&hsa_grp=116052592922&hsa_ad=610022043284&hsa_src=g&hsa_tgt=kwd-128349241&hsa_kw=randstad&hsa_mt=e&hsa_net=adwords&hsa_ver=3&gad_source=1&gclid=Cj0KCQjw7Py4BhCbARIsAMMx-_KA3f5KvoyfSMPPRTt8iPVFQAFFujmYonmovMSBNfB85mFpTI-AZvUaAhB7EALw_wcB'},
    {image: partnerImageTek, linkedIn: 'https://www.teksystems.com/en/business-transformation?utm_source=google&utm_medium=paid&utm_campaign=2021_GTM_SEM_Brand_august2021&vendor_id=4100&gad_source=1&gclid=Cj0KCQjw7Py4BhCbARIsAMMx-_Keq1SChpEH-opTnUqBnlbWVpA2Qsotck_2eqwBD0wmBX27Br0kLbwaAjxgEALw_wcB'},
    {image: partnerImageAccenture, linkedIn: 'https://www.accenture.com/us-en/industries/accenture-federal-services'},
    {image: partnerImageJade, linkedIn: 'https://www.jadeglobal.com/'},
]

// SERVICES

export const services = [
    { name: 'Contingent Workforce Management', link: '/services/contingent-workforce-management', img: imgOurServiceOne },
    { name: 'Recruiting Solutions', link: '/services/recruiting-solutions', img: imgOurServiceTwo },
    { name: 'Data & Analytics Solutions Expertise', link: '/services/data-analytics-solutions', img: imgOurServiceThree },
];

export const serviceSlug = {
    'contingent-workforce-management': { image: imgOurServiceOne },
    'recruiting-solutions': { image: imgOurServiceTwo },
    'data-analytics-solutions': { image: imgOurServiceThree },
};

// BLOGS

export const blogsMain = [
    { name: 'The Power of Edge Computing', content: 'Edge computing refers to a distributed computing paradigm where data ...', link: '/blogs/power-of-edge-computing', img: imgBlogOne },
    { name: 'The Role of AI in Automating BI Insights', content: 'Traditionally, BI tools required manual data analysis, and business ...', link: '/blogs/role-of-ai-in-automating-bi-insights', img: imgBlogTwo },
    { name: "Cloud-Based Business Intelligence", content: "In today's rapidly evolving business environment, data is a ...", link: '/blogs/cloud-based-business-intelligence', img: imgBlogThree },
    { name: "The Future of Business Intelligence", content: "Business Intelligence (BI) is constantly evolving ...", link: '/blogs/future-of-business-intelligence', img: imgBlogFour },
];

export const blogs = [
    { name: 'The Power of Edge Computing', content: 'Edge computing refers to a distributed computing paradigm where data ...', link: '/blogs/power-of-edge-computing', img: imgBlogOne },
    { name: 'The Role of AI in Automating BI Insights', content: 'Traditionally, BI tools required manual data analysis, and business ...', link: '/blogs/role-of-ai-in-automating-bi-insights', img: imgBlogTwo },
    { name: "Cloud-Based Business Intelligence", content: "In today's rapidly evolving business environment, data is a ...", link: '/blogs/cloud-based-business-intelligence', img: imgBlogThree },
    { name: "The Future of Business Intelligence", content: "Business Intelligence (BI) is constantly evolving ...", link: '/blogs/future-of-business-intelligence', img: imgBlogFour },
];

export const blogSlug = {
    'power-of-edge-computing': { image: imgBlogOne },
    'role-of-ai-in-automating-bi-insights': { image: imgBlogTwo },
    'cloud-based-business-intelligence': { image: imgBlogThree },
    'future-of-business-intelligence': { image: imgBlogFour }
};

// ABOUT US

export const values = [
    { icon: iconValueOne, name: 'Innovation in Technology', text: 'We are driven by a passion for technology, continuously innovating to provide the best staffing solutions that empower our clients to achieve success in an ever-evolving digital landscape.' },
    { icon: iconValueTwo, name: 'Commitment to Excellence', text: 'Our unwavering commitment to excellence is reflected in the quality of service we provide. We strive to exceed expectations, building long-term relationships based on trust and outstanding results.' },
    { icon: iconValueThree, name: 'Equity & Belonging', text: 'We believe in the power of diversity and are committed to fostering an inclusive environment where every voice is heard and respected. Our diverse team is our greatest asset, driving creativity and innovation.' },
    { icon: iconValueFour, name: 'Honesty & Clarity', text: 'We uphold the highest standards of integrity and transparency in all our interactions. Honesty and openness guide our decisions, ensuring we build trust with clients, candidates, and partners.' },
    { icon: iconValueFive, name: 'Pursuit of Growth', text: 'We are dedicated to continuous growth and learning, both as individuals and as an organization. Our pursuit of development fuels our drive to deliver the best solutions in the industry.' },
    { icon: iconValueSix, name: 'Community Engagement', text: 'We believe in giving back to the communities we serve. Our commitment to social responsibility motivates us to make a positive impact through charitable initiatives and community involvement.' },
];

export const leads = [
    {image: imgLeadDummy, name: 'Nayana Kapadia', designation: 'Founder of ITBuild Solutions LLC', linkedIn: 'https://www.linkedin.com/in/nayana-kapadia-585970220/'},
    {image: imgLeadDummy, name: 'Nikita Kapadia', designation: 'Director of Sales and Client Success'},
    {image: imgLeadOne, name: 'Deepak Kumar', designation: 'Vice President Talent Acquisition', linkedIn: 'https://www.linkedin.com/in/deepak-singh-a225a4b9/'},
];

export const achievements = [
    { image: imgCertificateOne, name: 'SBA 8(a) Certified', text: "In 2024, we achieved the XYZ certification, a testament to our team's dedication and commitment to quality and excellence." },
    { image: imgCertificateTwo, name: 'SBA WOSB Certified', text: "In 2024, we achieved the XYZ certification, a testament to our team's dedication and commitment to quality and excellence." },
];