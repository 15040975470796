import React from 'react';
import { useNavigate } from 'react-router-dom';

const CardService = ({ service }) => {
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate(service.link);
    window.scrollTo(0, 0);
  };

  return (
    <div className="service-dark-card" onClick={handleCardClick}>
      <img className="service-dark-image" src={service.img} alt={service.name} />
      <h4 className="service-dark-name">{service.name}</h4>
      <div className="service-card-arrow">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          fill="grey"
          className="bi bi-chevron-right"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
          />
        </svg>
      </div>
    </div>
  );
};

export default CardService;
