import React from 'react';
import { CtaHeader, CtaFooter, SectionHero, CardPartners } from '../component';
import { partners } from '../content';

const OurPartners = () => {

    return (
        <div className='common-wrapper'>
            <SectionHero  text="OUR PARTNERS" />

            <div className='common-container'>

                <CtaHeader heading='Our partners' text='Explore the visionary that fuel our success.' />

                <div className='partners-grid'>
                    {partners.map((partner, i) => (
                        <CardPartners key={i} partner={partner} />
                    ))}
                </div>

                <CtaFooter />
            </div>
        </div>
    );
};

export default OurPartners;
