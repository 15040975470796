import React from 'react'
import { useNavigate } from 'react-router-dom';

const CardBlog = ({blog}) => {

  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate(blog.link);
    window.scrollTo(0, 0);
  };

  return (
    <div className="blog-card-main">
      <img className="blog-card__image" src={blog.img} alt={blog.name} />
      <div className="blog-card__content">
        <div>
          <h3 className="blog-card__title">{blog.name}</h3>
          <p className="blog-card__text">{blog.content}</p>
        </div>
        <button className="blog-card-main__button" onClick={handleCardClick}>READ MORE</button>
      </div>
    </div>
  )
}

export default CardBlog
