import React from 'react'

const CtaHeader = ({ heading, text }) => {
  return (
    <div className="cta-header">
      <h4 className="cta-header__heading">{heading}</h4>
      <p className="cta-header__text">{text}</p>
    </div>
  )
}

export default CtaHeader
