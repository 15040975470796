import React from 'react';
import { CtaFooter, CtaHeader, CardBlog, CardBlogMain, SectionHero } from '../component';
import { blogs } from '../content';

const Blogs = () => {

  return (
    <div className="common-wrapper">
      
      <SectionHero  text="BLOGS" />

      <div className='common-container'>

        <CtaHeader
          heading="Our Recent Blogs"
          text="Stay informed and inspired with our latest insights and stories in our recent blogs!"
        />

        <section className="blogs-grid-wrapper">
          <CardBlogMain blog={blogs[0]} />
          <div className="blogs-grid">
            {blogs.slice(1).map((blog, i) => (
              <CardBlog key={i} blog={blog} />
            ))}
          </div>
        </section>

        <CtaFooter />
      </div>
    </div>
  );
};

export default Blogs;
