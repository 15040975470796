import React from 'react'

const NotFoundPage = () => {
  return (
    <div id='blank-page'>
      <h2 className='blank-page-title'>Page Not Found</h2>
    </div>
  )
}

export default NotFoundPage
