import React from 'react';
import ImgLogoItbWhite from '../images/logoItbWhite.png';
import { useNavigate } from 'react-router-dom';

const Footer = () => {

  const navigate = useNavigate()

  const onClickAbout = () => {
    navigate('/about');
    window.scrollTo(0, 0);
  }

  const onClickContact = () => {
    navigate('/contact');
    window.scrollTo(0, 0);
  }

  const onClickSoon = () => {
    navigate('/career')
    window.scrollTo(0, 0);
  }

  const onClickLinkedIn = () => {
    window.open('https://www.linkedin.com/company/itbuild-solutions/', "_blank")
  }

  return (
    <footer className="footer">
      <div className="footer__top">
        <div className="footer__left">
          <img className='footer__logo' src={ImgLogoItbWhite} alt='logo itb' />
          <p className='footer__text'>Transform, innovate, and build with ITBUILD: Expert talent and strategic IT solutions for your success.</p>
        </div>
        <div className="footer__right">
          <div className="footer__section footer__section--company">
            <h4 className="footer__heading">COMPANY</h4>
            <ul className="footer__list">
              <li onClick={onClickAbout} className="footer__list-item">About Us</li>
              <li onClick={onClickContact} className="footer__list-item">Contact Us</li>
            </ul>
          </div>
          <div className="footer__section footer__section--social">
            <h4 className="footer__heading">SOCIAL</h4>
            <ul className="footer__list">
              <li onClick={onClickSoon} className="footer__list-item">Twitter</li>
              <li onClick={onClickSoon} className="footer__list-item">Facebook</li>
              <li onClick={onClickLinkedIn} className="footer__list-item">LinkedIn</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="footer__bottom">
        <p className="footer__copyright">Copyright © 2024 ITBuild Solutions. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
