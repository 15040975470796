import React from 'react'

const CardValue = ({val}) => {
  return (
    <div className="value-card">
      <img src={val.icon} className="value-icon" alt={`${val.name} icon`} />
      <h6 className="value-name">{val.name}</h6>
      <p className="value-text">{val.text}</p>
    </div>
  )
}

export default CardValue
