import React from 'react'
import { SectionHero, CardMissionVision, CardValue, CardLead, CardAchievement } from '../component'
import { values, leads, achievements } from '../content'

const AboutUs = () => {
    return (
        <div className='common-wrapper'>

            <SectionHero  text="ABOUT US" />

            <div className='aboutus-container'>
                
                <CardMissionVision />

                <div className="values-section">
                    <h4 className='aboutus-title'>Our Values</h4>
                    <div className="values-grid">
                        {values.map((val, id) => (
                            <CardValue val={val} key={id} />
                        ))}
                    </div>
                </div>

                <div className="leads-section">
                    <h4 className='aboutus-title'>Our Leads</h4>
                    <div className="leads-grid">
                        {leads.map((lead, id) => (
                            <CardLead lead={lead} id={id} />
                        ))}
                    </div>
                </div>

                {/* <div className="achievements-section">
                    <h4 className='aboutus-title'>Our Achievements</h4>
                    <div className="achievements-grid">
                        {achievements.map((certi, id) => (
                            <CardAchievement certi={certi} id={id} />
                        ))}
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default AboutUs
