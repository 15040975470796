import Markdown from "markdown-to-jsx"
import { useEffect, useState } from "react"
import { useParams, useNavigate } from 'react-router-dom';
import { blogSlug } from '../content'
import { CtaFooter, SectionHero } from '../component'

const Post = () => {
    const { slug } = useParams();
    const [postContent, setPostContent] = useState("");
    const [postMeta, setPostMeta] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        import(`../content/blogs/${slug}.md`)
        .then(res => {
            fetch(res.default)
            .then(res => res.text())
            .then(res => setPostContent(res))
            .catch(err => console.log(err))
        })
        
        if (blogSlug[slug]) {
            setPostMeta(blogSlug[slug])
        }
    }, []);

    const onClickBlogs = () => {
        navigate('/blogs');
        window.scrollTo(0, 0);
    }

    return (
        <div id="markdown-wrapper" className="common-wrapper">
            
            <SectionHero  text="BLOGS" />

            <div className="markdown-container">
                {postMeta && <img className="markdown-image" src={postMeta.image} alt="logo main" />}
                <div className="markdown-content">
                    <Markdown>
                        {postContent}
                    </Markdown>
                </div>
            </div>
            
            <div className="markdown-btn-container">
                <button onClick={onClickBlogs} className="markdown-btn">GO BACK TO BLOGS</button>
            </div>

            <div className="common-container">
                <CtaFooter />
            </div>
        </div>
    )
}

export default Post