import React from 'react';
import { contactDetails } from '../content';
import { CtaFooter, CtaHeader, SectionHero } from '../component';

const ContactUsPage = () => {
  return (
    <div className="contact-us">
      <SectionHero text="CONTACT US" />

      <div className='common-container'>

        <CtaHeader
            heading="Connect with us"
            text="Feel free to reach out via the contact form or give us a call—whichever suits you best!"
        />

        <div className="contact-content">
            <div className="contact-map">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3344.779210187271!2d-97.0676991244302!3d33.03594687355652!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c2d134eddf8bf%3A0x26529caf40109ce6!2s3700%20River%20Walk%20Dr%2C%20Flower%20Mound%2C%20TX%2075028!5e0!3m2!1sen!2sus!4v1730165605570!5m2!1sen!2sus"
                width="600"
                height="600"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
            </div>

            <div className="contact-details">
            <h6 className="contact-heading">Head Office</h6>
            <div className="contact-item">
                <img src={contactDetails.iconPhone} alt="phone icon" />
                <p>{contactDetails.headNumber}</p>
            </div>
            <div className="contact-item">
                <img src={contactDetails.iconEmail} alt="email icon" />
                <p>{contactDetails.headEmail}</p>
            </div>
            <div className="contact-item">
                <img src={contactDetails.iconLocation} alt="location icon" />
                <p>{contactDetails.headAddress}</p>
            </div>

            <h6 className="contact-heading">India Office</h6>
            <div className="contact-item">
                <img src={contactDetails.iconPhone} alt="phone icon" />
                <p>{contactDetails.indiaNumber}</p>
            </div>
            <div className="contact-item">
                <img src={contactDetails.iconEmail} alt="email icon" />
                <p>{contactDetails.indiaEmail}</p>
            </div>
            <div className="contact-item">
                <img src={contactDetails.iconLocation} alt="location icon" />
                <p>{contactDetails.indiaAddress}</p>
            </div>
            </div>
        </div>

        <CtaFooter />
      </div>
    </div>
  );
};

export default ContactUsPage;
