import React from 'react';

const ComingSoonPage = () => {
  return (
    <div id='blank-page'>
      <h2 className='blank-page-title'>COMING SOON!</h2>
    </div>
  );
};

export default ComingSoonPage;
