import React from 'react'

const SectionHero = ({text}) => {
  return (
    <div id='section-hero'>
      <h2 className='section-hero__text'>{text}</h2>
    </div>
  )
}

export default SectionHero
