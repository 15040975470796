import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { navbarMenuItems } from '../content';
import ImgLogoItb from '../images/logoItbInverseLong.png';

const Navbar = () => {
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate()

  const goToHome = () => {
    navigate('/')
  }

  return (
    <nav className="navbar">
      <img onClick={goToHome} src={ImgLogoItb} alt="ITBuild Logo" className="navbar__logo" />

      <ul className={isMobile ? 'navbar__links navbar__links--mobile' : 'navbar__links'}>
        {navbarMenuItems.map((menu, i) => (
          <li key={i} className="navbar__item">
            <NavLink
              to={menu.link}
              exact="true" // React Router v6+ requires "true" as a string
              className={({ isActive }) => isActive ? "navbar__link navbar__link--active" : "navbar__link"}
              onClick={() => setIsMobile(false)}
            >
              {menu.text}
            </NavLink>
          </li>
        ))}
        <li className="navbar__item navbar__item--contact">
          <NavLink
            to="/contact"
            className="navbar__button--contact"
            onClick={() => setIsMobile(false)}
          >
            Contact Us
          </NavLink>
        </li>
      </ul>

      {isMobile && <div className="navbar__overlay" onClick={() => setIsMobile(false)}></div>}

      <div
        className={`navbar__hamburger ${isMobile ? 'navbar__hamburger--open' : ''}`}
        onClick={() => setIsMobile(!isMobile)}
      >
        <span className="navbar__bar"></span>
        <span className="navbar__bar"></span>
        <span className="navbar__bar"></span>
      </div>
    </nav>
  );
};

export default Navbar;
